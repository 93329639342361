import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withTrans } from '../../i18n/withTrans';

const PopUpErrors = ({ t, open, handleClose, text }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert severity="error">{t(`errors.${text}`)}</Alert>
    </Snackbar>
  );
};

export default withTrans(PopUpErrors);

PopUpErrors.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

PopUpErrors.defaultProps = {
  open: undefined
};
