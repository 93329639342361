import React from 'react';
import { withTrans } from '../../i18n/withTrans';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import { AiOutlineDown } from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';
import VisitorAdultInfoForm from './VisitorAdultInfoForm';
import VisitorChildInfoForm from './VisitorChildInfoForm';

const VisitorsInfosCollapse = ({
  t,
  index,
  reservationState,
  reservationDispatch,
  tab
}) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<AiOutlineDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>
            {t(`reservationPage.nbPersons.visitor`)} {index + 1}
            {reservationState.errors_validation?.includes(index) && (
              <FiAlertCircle style={{ color: 'red', marginLeft: 16 }} />
            )}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {index === 0 ? (
            <VisitorAdultInfoForm
              indexVisitor={index}
              reservationDispatch={reservationDispatch}
              reservationState={reservationState}
            />
          ) : (
            <VisitorChildInfoForm
              indexVisitor={index}
              visitorType={tab.visitorType}
              reservationDispatch={reservationDispatch}
              reservationState={reservationState}
            />
          )}
          <p className="mandatory-fields-text">
            {t(`visitors.form.mandatory_fields`)}
          </p>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default withTrans(VisitorsInfosCollapse);
