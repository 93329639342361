/* eslint-disable no-console */
import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import { withTrans } from '../i18n/withTrans';
import DateSelection from '../components/SlotSelection/DateSelection';
import fullTicketImg from '../assets/images/ticket-full.jpg';
import reducedTicketImg from '../assets/images/ticket-reduced.jpg';
import childTicketImg from '../assets/images/ticket-child.jpg';
import freeTicketImg from '../assets/images/ticket-free.jpg';
import VisitorsInfo from '../components/SlotSelection/VisitorsInfo';
import OrderRecap from '../components/SlotSelection/OrderRecap';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import ShowPayment from '../components/Payment/ShowPayment';
import { navigate } from 'gatsby';
import useAuthContext from '../contexts/AuthContext';
import { useHandleResize } from '../utils';
import CalendarNoteModal from '../components/SlotSelection/CalendarNoteModal';
import PopUpErrors from '../components/ReservationPage/PopUpErrors';
import MyVisitMobileRender from '../components/MyVisitRende/MyVisitMobileRender';
import MyVisitWebRender from '../components/MyVisitRende/MyVisitWebRender';
import ModalConfirmOrderPayment from '../components/Payment/ModalConfirmOrderPayment';

function reducer(state, action) {
  return { ...state, ...action };
}
const isBrowser = typeof window !== 'undefined';

const SlotSelection = ({ i18n, t }) => {
  const { width } = useHandleResize() || 993;
  const steps = ['visitSelection', 'visitorsInfos', 'commandRecap', 'payment'];
  const { dispatchAPI } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, {
    activeStep: 0,
    scrollPos: 0,
    activeTab: 0,
    manifestations_with_jauge: [],
    language: i18n.language === 'fr-FR' ? 'fr' : i18n.language,
    selectedSlot: {},
    showCalendarNote: false
  });
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorText, setErrortext] = useState('general');
  const [isLoading, setIsLoading] = useState(false);

  let full_price = '0';
  let reduced_price = '0';
  let child_price = '0';
  let free_price = '0';
  let visitType = '';
  let oldReservation = {};
  let step = '';

  if (isBrowser) {
    full_price = localStorage.getItem('full_price');
    reduced_price = localStorage.getItem('reduced_price');
    child_price = localStorage.getItem('child_price');
    free_price = localStorage.getItem('free_price');
    visitType = localStorage.getItem('visitType');
    oldReservation = localStorage.getItem('panier');
    step = localStorage.getItem('step');
  }
  const totalVisitor =
    JSON.parse(full_price) +
    JSON.parse(reduced_price) +
    JSON.parse(child_price) +
    JSON.parse(free_price);

  useEffect(() => {
    if (step && JSON.parse(step) !== 0) {
      dispatch({ activeStep: JSON.parse(step) });
      localStorage.removeItem('step');
    }
  }, [step]);

  useEffect(() => {
    dispatch({
      oldReservation: JSON.parse(oldReservation)
      /* ticketsList: JSON.parse(oldReservation).ticketsList.map(el => ({
       ...el,
       status: 'old',
     })),*/
    });
  }, [oldReservation]);

  const onCancelPanier = async (ligne) => {
    try {
      await dispatchAPI('POST', {
        url: `/gts/sup-panier`,
        body: { ligne: ligne }
      });
      dispatch({ lignePanier: '' });
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  const setTicketImg = (visitorType) => {
    let ticketImg = '';
    switch (visitorType) {
      case 'full_price':
        ticketImg = fullTicketImg;
        break;
      case 'reduced_price':
        ticketImg = reducedTicketImg;
        break;
      case 'child_price':
        ticketImg = childTicketImg;
        break;
      case 'free_price':
        ticketImg = freeTicketImg;
        break;
      default:
        ticketImg = fullTicketImg;
        break;
    }
    return ticketImg;
  };

  const [visibility, setVisibility] = React.useState('hidden');
  const styles = {
    visibility: `${visibility}`
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <DateSelection
              reservationState={state}
              reservationDispatch={dispatch}
              t={t}
            />
          </>
        );
      case 1:
        return (
          <VisitorsInfo
            reservationState={state}
            reservationDispatch={dispatch}
          />
        );
      case 2:
        return (
          <OrderRecap reservationState={state} reservationDispatch={dispatch} />
        );
      case 3:
        return (
          <ShowPayment
            reservationState={state}
            reservationDispatch={dispatch}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  const addNbVisitors = (type) => {
    let indexTicket = 0;
    const newVisitorsList = state.visitorsList || [];
    const newTicketsList = state.ticketsList;

    switch (type) {
      case 'full_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'full_price'
        );
        localStorage.setItem('full_price', JSON.parse(full_price) + 1);
        newTicketsList[indexTicket].price =
          visitType === '17'
            ? 26 * (JSON.parse(full_price) + 1)
            : 23 * (JSON.parse(full_price) + 1);
        break;
      case 'reduced_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'reduced_price'
        );
        localStorage.setItem('reduced_price', JSON.parse(reduced_price) + 1);
        newTicketsList[indexTicket].price =
          16.5 * (JSON.parse(reduced_price) + 1);
        break;
      case 'child_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'child_price'
        );
        localStorage.setItem('child_price', JSON.parse(child_price) + 1);
        newTicketsList[indexTicket].price =
          10.0 * (JSON.parse(child_price) + 1);
        break;
      case 'free_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'free_price'
        );
        localStorage.setItem('free_price', JSON.parse(free_price) + 1);
        // newTicketsList[indexTicket].price = 10.00 * (JSON.parse(free_price) + 1)
        break;
      default:
        dispatch();
        break;
    }

    newVisitorsList.push({
      visitorType: type,
      // priceType: type === 'adult' ? 'Plein Tarif' : 'Tarif réduit',
      price: type === 'full_price' ? 23 : type === 'reduced_price' ? 16.5 : 0.0
    });

    dispatch({
      ticketsList: [...newTicketsList],
      visitorsList: [...newVisitorsList]
    });
  };

  const subtractNbVisitors = (type) => {
    let indexTicket = 0;
    let indexVisitor = '';
    const newVisitorsList = state.visitorsList || [];
    let newTicketsList = state.ticketsList;
    switch (type) {
      case 'full_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'full_price'
        );
        localStorage.setItem(
          'full_price',
          JSON.stringify(JSON.parse(full_price) - 1)
        );
        newTicketsList[indexTicket].price =
          visitType === '17'
            ? 26 * (JSON.parse(full_price) - 1)
            : 23 * (JSON.parse(full_price) - 1);
        break;
      case 'reduced_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'reduced_price'
        );
        localStorage.setItem(
          'reduced_price',
          JSON.stringify(JSON.parse(reduced_price) - 1)
        );
        newTicketsList[indexTicket].price =
          16.5 * (JSON.parse(reduced_price) - 1);
        break;
      case 'child_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'child_price'
        );
        localStorage.setItem(
          'child_price',
          JSON.stringify(JSON.parse(child_price) - 1)
        );
        newTicketsList[indexTicket].price =
          10.0 * (JSON.parse(child_price) - 1);
        break;
      case 'free_price':
        indexTicket = state.ticketsList.findIndex(
          (el) => el.visitorType === 'free_price'
        );
        localStorage.setItem(
          'free_price',
          JSON.stringify(JSON.parse(free_price) - 1)
        );
        // newTicketsList[indexTicket].price = 10.00 * (JSON.parse(free_price) - 1);
        break;
      default:
        dispatch();
        break;
    }
    if (
      (type === 'full_price'
        ? JSON.parse(full_price)
        : type === 'reduced_price'
        ? JSON.parse(reduced_price)
        : type === 'child_price'
        ? JSON.parse(child_price)
        : JSON.parse(free_price)) -
        1 ===
      0
    ) {
      newTicketsList = newTicketsList.filter((el) => {
        return el.visitorType !== type;
      });
    }
    (state.visitorsList || []).forEach((visitor, index) => {
      if (
        (!visitor.nom ||
          !visitor.prenom ||
          !visitor.datenaissance ||
          !visitor.email) &&
        visitor.visitorType.includes(type)
      ) {
        indexVisitor = index;
      }
    });
    newVisitorsList.splice(indexVisitor, 1);

    dispatch({
      ticketsList: [...newTicketsList],
      visitorsList: [...newVisitorsList],
      activeTab:
        state.activeTab === indexVisitor ? state.activeTab - 1 : state.activeTab
    });
  };

  const onHandleVisitorNbChange = (type, value) => {
    const price =
      type === 'full_price'
        ? 23
        : type === 'reduced_price'
        ? 16.5
        : type === 'child_price'
        ? 10
        : 0;
    const newTicketsList = state.ticketsList;
    const indexTicket = state.ticketsList.findIndex(
      (el) => el.visitorType === type
    );
    localStorage.setItem(`${type}`, value || '');
    newTicketsList[indexTicket].price =
      visitType === '17' ? 26 * value : price * value;
    dispatch({ ticketsList: [...newTicketsList] });
  };

  const onDeleteTicket = (type, status) => {
    if (status === 'old') {
      const reserv = {
        ...state.oldReservation,
        ticketsList: [
          ...state.oldReservation?.ticketsList.filter(
            (el) => el.visitorType !== type
          )
        ],
        visitorsList: [
          ...(state.oldReservation?.visitorsList || []).filter(
            (el) => !el.visitorType.includes(type)
          )
        ],
        full_price: type === 'full_price' ? 0 : state.oldReservation.full_price,
        reduced_price:
          type === 'reduced_price' ? 0 : state.oldReservation.reduced_price,
        child_price:
          type === 'child_price' ? 0 : state.oldReservation.child_price,
        free_price: type === 'free_price' ? 0 : state.oldReservation.free_price
      };
      (state?.oldReservation?.lignePanierList || []).forEach((line) => {
        (async () => {
          await onCancelPanier(line.lignePanier);
        })();
      });
      if (
        JSON.parse(reserv.free_price) +
          JSON.parse(reserv.child_price) +
          JSON.parse(reserv.reduced_price) +
          JSON.parse(reserv.full_price) ===
        0
      ) {
        delete state.oldReservation;
        localStorage.removeItem('panier');
      } else {
        dispatch({ oldReservation: reserv });
        localStorage.setItem('panier', JSON.stringify(reserv));
      }
    } else {
      const indexVisitor = (state.visitorsList || []).findIndex((el) =>
        el.visitorType.includes(type)
      );
      dispatch({
        ticketsList: [
          ...state.ticketsList.filter((el) => el.visitorType !== type)
        ],
        visitorsList: [
          ...(state.visitorsList || []).filter(
            (el) => !el.visitorType.includes(type)
          )
        ],
        activeTab:
          state.activeTab === indexVisitor
            ? state.activeTab - 1
            : state.activeTab
      });
      localStorage.setItem(`${type}`, '0');
    }
  };

  const onCreatePanier = async (ligne) => {
    try {
      const list = [];

      dispatchAPI('POST', {
        url: '/debug',
        body: {
          location: 'ma-visite.js line 391',
          ligne: ligne,
          nombre: isBrowser && localStorage.getItem(state.ticketsList),
          date: state?.selectedSlot?.date,
          price: state.ticketsList.map((ti) => ti.price),
          hour: state?.selectedSlot?.heure,
          visit: state?.selectedSlot?.theme,
          visitId: state?.selectedSlot?.id,
          disponibility: state?.selectedSlot?.jauge,
          expositionId: state?.selectedSlot?.expositionId,
          prices: state?.selectedSlot?.prices || [],
          visitors: state.visitorsList
        }
      });

      const { data } = await dispatchAPI('POST', {
        url: `/gts/panier`,
        body: {
          ligne: ligne,
          nombre: isBrowser && localStorage.getItem(state.ticketsList),
          date: state?.selectedSlot?.date,
          price: state.ticketsList.map((ti) => ti.price),
          hour: state?.selectedSlot?.heure,
          visit: state?.selectedSlot?.theme,
          visitId: state?.selectedSlot?.id,
          disponibility: state?.selectedSlot?.jauge,
          expositionId: state?.selectedSlot?.expositionId,
          prices: state?.selectedSlot?.prices || [],
          visitors: state.visitorsList
        }
      });

      dispatchAPI('POST', {
        url: '/debug',
        body: {
          location: 'ma-visite.js line 426',
          data: data
        }
      });

      list.push({
        nombre:
          isBrowser &&
          localStorage.getItem(state.ticketsList.map((ti) => ti.visitorType)),
        produit: state.ticketsList.map((ti) => ti.visitorType),
        lignePanier: data.lignePanier,
        _id: data._id,
        data
      });

      dispatchAPI('POST', {
        url: '/debug',
        body: {
          location: 'ma-visite.js line 426',
          list: list
        }
      });

      return list;
    } catch (e) {
      if (e.response) {
        setAlertOpen(true);
        setErrortext('general');
        if (process.env.NODE_ENV === 'production') {
          try {
            await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
              method: 'POST',
              body: JSON.stringify({
                error: {
                  title: `[CREATE-CART-ITEM]: ${e?.message}`,
                  description: e.toString()
                },
                path: window.location.href
              }),
              headers: new Headers({ 'Content-type': 'application/json' })
            });
          } catch (e) {
            console.error("Impossible de signaler l'erreur");
          }
        }
      }
    }
  };

  const onCalculateTotalPrice = () => {
    let total = 0;

    (state.ticketsList || []).forEach((ticket) => {
      total += ticket.price;
    });

    (state.oldReservation?.ticketsList || []).forEach((ticket) => {
      total += ticket.price;
    });
    dispatch({ totalPrice: total.toFixed(2) });
  };

  const onValidateSlot = () => {
    const errors = [];
    switch (state.activeStep) {
      case 0:
        if (!state?.ticketsList?.length) {
          errors.push('slot');
          dispatch({ errors: ['slot'], openErrorsSnack: true });
        }
        break;
      case 1:
        state.visitorsList.forEach((visitor, index) => {
          if (index === 0) {
            if (
              !visitor.nom ||
              !visitor.prenom ||
              !visitor.email ||
              !visitor.telephone ||
              (Array.isArray(state.errors_validation) &&
                state.errors_validation.some(
                  (error) => typeof error === 'string'
                ))
            ) {
              errors.push(index);
            }
          } else {
            if (!visitor.nom || !visitor.prenom) {
              errors.push(index);
            }
          }
        });
        dispatch({
          errors_validation: [...(state.errors_validation || []), ...errors],
          openValErrorsSnack: errors.length !== 0
        });
        break;
    }
    if (errors.length) {
      setIsLoading(false);
    } else {
      if (state.activeStep === 1) {
        (async () => {
          const ligne = await onCreatePanier(state.selectedSlot.id);
          let ticketsNb = 0;
          ligne.forEach((el) => {
            ticketsNb += JSON.parse(el.nombre);
          });

          dispatchAPI('POST', {
            url: '/debug',
            body: {
              location: 'ma-visite.js line 533',
              ligne: ligne,
              stateSelectedSlotId: state.selectedSlot.id,
              ticketsNb: ticketsNb,
              totalVisitor: totalVisitor
            }
          });
          if (
            ticketsNb === totalVisitor ||
            ligne[0].data.lockBasketResult.isLocked ||
            ligne[0].data.lockBasketResult.basketItems[0].entries.length ===
              totalVisitor
          ) {
            dispatch({
              lignePanierList: ligne[0].data.lockBasketResult.basketItems
            });
            if (width <= 992) {
              dispatch({ showCalendarNote: true });
            } else {
              dispatch({ activeStep: state.activeStep + 1 });
            }
          } else {
            dispatch({ errorPanier: true });
          }
        })();
      } else {
        dispatch({ activeStep: state.activeStep + 1 });
      }
    }
  };

  const handleClose = () => {
    dispatch({
      openErrorsSnack: false,
      openValErrorsSnack: false,
      errorPanier: false
    });
  };

  const goBackStep = () => {
    if (state?.activeStep === 1) {
      if (state?.lignePanierList?.length) {
        (state?.lignePanierList || []).forEach((el) => {
          (async () => {
            await onCancelPanier(el.lignePanier);
          })();
        });
      }
    }
    if (state.activeStep === 0) {
      navigate('/reservation/');
    } else {
      dispatch({ activeStep: state.activeStep - 1 });
    }
  };

  const onCancel = () => {
    if (state?.lignePanierList && (state.lignePanierList || []).length) {
      (state?.lignePanierList || []).forEach((el) => {
        (async () => {
          await onCancelPanier(el.lignePanier);
        })();
      });
    }
    navigate('/reservation/');
  };

  const goBackToLanding = () => {
    if (state?.lignePanierList && (state?.lignePanierList || []).length) {
      (state?.lignePanierList || []).forEach((el) => {
        (async () => {
          await onCancelPanier(el.lignePanier);
        })();
      });
    }
    if (
      state?.oldReservation &&
      (state?.oldReservation?.lignePanierList || []).length
    ) {
      (state?.oldReservation?.lignePanierList || []).forEach((el) => {
        (async () => {
          await onCancelPanier(el.lignePanier);
        })();
      });
    }
    navigate('/');
  };

  const onShoppingContinue = () => {
    localStorage.setItem(
      'panier',
      JSON.stringify({
        full_price: full_price,
        reduced_price: reduced_price,
        child_price: child_price,
        free_price: free_price,
        visitorsList: state.visitorsList.map((el) => ({
          ...el,
          status: 'old'
        })),
        visitType: visitType,
        selectedSlot: state.selectedSlot,
        lignePanierList: state.lignePanierList,
        ticketsList: (state?.ticketsList || []).map((el) => ({
          ...el,
          status: 'old'
        })),
        totalPrice: state.totalPrice,
        receiverList: state.receiverList
      })
    );
    navigate('/reservation/');
  };

  useEffect(() => {
    onCalculateTotalPrice();
  }, [state.ticketsList, state.oldReservation]);

  return (
    <Layout indexStep={state.activeStep} pageName="slot_selection">
      <Helmet>
        <title>{t('helmet.slot_selection')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <div style={{ display: 'flex' }}>
        <ModalConfirmOrderPayment state={state} dispatch={dispatch} />
        {state.showCalendarNote && (
          <CalendarNoteModal state={state} dispatch={dispatch} />
        )}
        <PopUpErrors
          t={t}
          open={alertOpen}
          text={errorText}
          handleClose={() => {
            setAlertOpen(false);
            setErrortext('general');
          }}
        />
        <PopUpErrors
          open={state.openErrorsSnack}
          handleClose={handleClose}
          text="slotIncomplete"
        />
        <PopUpErrors
          open={state.openValErrorsSnack}
          handleClose={handleClose}
          text="fieldsIncomplete"
        />
        <PopUpErrors
          open={state.errorPanier}
          handleClose={handleClose}
          text="cart_line"
        />
        <MyVisitMobileRender
          steps={steps}
          onHandleVisitorNbChange={onHandleVisitorNbChange}
          getStepContent={getStepContent}
          state={state}
          dispatch={dispatch}
          onCancel={onCancel}
          goBackStep={goBackStep}
          onValidateSlot={onValidateSlot}
          onShoppingContinue={onShoppingContinue}
          setTicketImg={setTicketImg}
          setVisibility={setVisibility}
          subtractNbVisitors={subtractNbVisitors}
          addNbVisitors={addNbVisitors}
          onDeleteTicket={onDeleteTicket}
          styles={styles}
          goBackToLanding={goBackToLanding}
        />
        <MyVisitWebRender
          steps={steps}
          onHandleVisitorNbChange={onHandleVisitorNbChange}
          getStepContent={getStepContent}
          state={state}
          dispatch={dispatch}
          onCancel={onCancel}
          goBackStep={goBackStep}
          onValidateSlot={onValidateSlot}
          onShoppingContinue={onShoppingContinue}
          setTicketImg={setTicketImg}
          setVisibility={setVisibility}
          subtractNbVisitors={subtractNbVisitors}
          addNbVisitors={addNbVisitors}
          onDeleteTicket={onDeleteTicket}
          styles={styles}
          goBackToLanding={goBackToLanding}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </Layout>
  );
};

export default withTrans(SlotSelection);

SlotSelection.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string.isRequired
  }).isRequired,
  t: PropTypes.func.isRequired
};
