import React from "react";
import {withTrans} from "../../i18n/withTrans";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import { AiOutlineDown } from "react-icons/ai";
import CartList from "./CartList";

const CartListCollapse = ({t, reservationState, reservationDispatch, onHandleVisitorNbChange, setTicketImg, setVisibility, subtractNbVisitors, addNbVisitors, onDeleteTicket, styles, ...props}) => {

    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<AiOutlineDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h2 className="cart-title"><span>{t('myOrderSocket')}</span> <span><strong>{reservationState.totalPrice} €</strong>{t('cart.vat-included')}</span></h2>
                </AccordionSummary>
                <AccordionDetails>
                   <CartList
                       onHandleVisitorNbChange={onHandleVisitorNbChange}
                       reservationState={reservationState}
                       reservationDispatch={reservationDispatch}
                       setTicketImg={setTicketImg}
                       setVisibility={setVisibility}
                       subtractNbVisitors={subtractNbVisitors}
                       addNbVisitors={addNbVisitors}
                       onDeleteTicket={onDeleteTicket}
                       styles={styles}
                   />
                </AccordionDetails>
            </Accordion>
        </>
    )
};

export default withTrans(CartListCollapse);
