import React, { useState } from 'react';
import './stripe.css';
import { Box, Card } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { withTrans } from '../../i18n/withTrans';
import SystemPay from './SystemPay';

const nodeEnv = process.env.NODE_ENV;

// const promise = loadStripe(
//   nodeEnv === 'development'
//     ? 'pk_test_51JeKwsBS3Hg7ErTmSXkrtcSoEToanwqJFSMq4YE5279XXO4fvs2yBw6SGV7ECVeQx5WC22dF5pYJMprc3QBdvIh000qqvaDvEl'
//     : 'pk_live_51JeKwsBS3Hg7ErTmxIasp8tLBPEJgsQSxWJlo9YjVC0TR6QE4rFZLl97qvtA9Hh5BJGNC0Ha8V9atBoaVuWd7n58009UeG1zBi'
// );

const ShowPayment = ({ reservationState, reservationDispatch, t }) => {
  const [messageTitle, setMessageTitle] = useState();
  const [message, setMessage] = useState();

  return (
    <Box className="payment-box">
      {/* <Button onClick={() => setVisible(!visible)}>login</Button>
      <ModalForLogin visible={visible} setVisible={setVisible} /> */}
      <Card className="payment-card">
        <h2>{t('payment.instructions')}</h2>
        <p>{`${t('payment.total')} ${reservationState.totalPrice} €`}</p>
        <div>
          <SystemPay
            state={reservationState}
            setMessageTitle={setMessageTitle}
            setMessage={setMessage}
            reservationDispatch={reservationDispatch}
          />
          {/* On hold - waiting for Manatour to unblock their Stripe account */}
          {/* <Elements stripe={promise}>
            <StripeButton
              reservationState={reservationState}
              reservationDispatch={reservationDispatch}
              amount={reservationState.totalPrice}
              setMessageTitle={setMessageTitle}
              setMessage={setMessage}
            />
          </Elements> */}
        </div>
      </Card>
      {message && messageTitle && (
        <Card className="message-card">
          <h2>{t(`payment.${messageTitle}`)}</h2>
          {/* Add returnMessageTitle inside h2 */}
          <p>{t(`payment.${message}`)}</p>
        </Card>
      )}
    </Box>
  );
};

export default withTrans(ShowPayment);
