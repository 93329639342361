import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tab, Tabs } from '@material-ui/core';
import VisitorAdultInfoForm from './VisitorAdultInfoForm';
import VisitorChildInfoForm from './VisitorChildInfoForm';
import { withTrans } from '../../i18n/withTrans';
import visitorFormImg from '../../assets/images/step-2-form_bg.jpg';
import { NextTabIcon, PreviousTabIcon } from '../../assets/customIcons';
import { FiAlertCircle } from 'react-icons/fi';
import { useHandleResize } from '../../utils';
import VisitorsInfosCollapse from './visitorsInfosCollapse';

const isBrowser = typeof window !== 'undefined';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

const VisitorsInfo = ({ reservationState, reservationDispatch, t }) => {
  let full_price = '0';
  let reduced_price = '0';
  let child_price = '0';
  let free_price = '0';
  let visitType = '';

  if (isBrowser) {
    full_price = localStorage.getItem('full_price');
    reduced_price = localStorage.getItem('reduced_price');
    child_price = localStorage.getItem('child_price');
    free_price = localStorage.getItem('free_price');
    visitType = localStorage.getItem('visitType');
  }

  const { width } = useHandleResize();

  const tabsConstruction = () => {
    const listVisitors = [];
    for (let i = 1; i <= full_price; i++) {
      listVisitors.push({
        visitorType: `full_price`,
        priceType: visitType === '17' ? 'Tarif unique' : 'Plein tarif',
        price: visitType === '17' ? 26 : 23
      });
    }

    for (let i = 1; i <= reduced_price; i++) {
      listVisitors.push({
        visitorType: `reduced_price`,
        priceType: 'Tarif réduit',
        price: 16.5
      });
    }

    for (let i = 1; i <= child_price; i++) {
      listVisitors.push({
        visitorType: `child_price`,
        priceType: 'Tarif enfant',
        price: 10.0
      });
    }

    for (let i = 1; i <= free_price; i++) {
      listVisitors.push({
        visitorType: `free_price`,
        priceType: 'Gratuit',
        price: 0.0
      });
    }
    return {
      listVisitors
    };
  };

  useEffect(() => {
    reservationDispatch({
      visitorsList: tabsConstruction().listVisitors
    });
  }, [reservationDispatch]);

  const handleTabChange = (_, tab) => {
    reservationDispatch({ activeTab: tab });
  };

  const nextTab = () => {
    reservationDispatch({ activeTab: reservationState.activeTab + 1 });
  };

  const lastTab = () => {
    reservationDispatch({ activeTab: reservationState.activeTab - 1 });
  };

  return (
    <>
      <div className="visitor-info-wrapper">
        <h2>{t('visitors.title')}</h2>
        <div
          className="visitor-tabs-wrapper"
          style={{
            background: `url(${visitorFormImg}) no-repeat`,
            backgroundSize: ' cover',
            backgroundPosition: '100% 46%'
          }}
        >
          <p>
            {t('visitors.instructions-1')}
            <span>{t('visitors.instructions-2')}</span>
          </p>
          {width <= 1260 ? (
            (reservationState.visitorsList || []).map((tab, index) => (
              <VisitorsInfosCollapse
                key={index}
                index={index}
                reservationState={reservationState}
                reservationDispatch={reservationDispatch}
                tab={tab}
              />
            ))
          ) : (
            <Tabs value={reservationState.activeTab} onChange={handleTabChange}>
              {(reservationState.visitorsList || []).map((tab, index) => (
                <Tab
                  key={index}
                  label={
                    <span>
                      {t(`reservationPage.nbPersons.visitor`)} {index + 1}
                      {reservationState.errors_validation?.includes(index) && (
                        <FiAlertCircle
                          style={{ color: 'red', marginLeft: 16 }}
                        />
                      )}
                    </span>
                  }
                />
              ))}
            </Tabs>
          )}

          {width > 1260 &&
            (reservationState.visitorsList || []).map((tab, index) => (
              <TabPanel
                key={index}
                index={index}
                value={reservationState.activeTab}
                className="visitor-form-tabpanel"
              >
                {index === 0 ? (
                  <VisitorAdultInfoForm
                    indexVisitor={index}
                    reservationDispatch={reservationDispatch}
                    reservationState={reservationState}
                  />
                ) : (
                  <VisitorChildInfoForm
                    indexVisitor={index}
                    visitorType={tab.visitorType}
                    reservationDispatch={reservationDispatch}
                    reservationState={reservationState}
                  />
                )}
                <p className="mandatory-fields-text">
                  {t(`visitors.form.mandatory_fields`)}
                </p>
                <div style={{ position: 'absolute', bottom: 16, right: 20 }}>
                  {index > 0 &&
                  index < reservationState.visitorsList.length - 1 ? (
                    <>
                      <IconButton onClick={() => lastTab()}>
                        <PreviousTabIcon />
                      </IconButton>
                      <IconButton onClick={() => nextTab()}>
                        <NextTabIcon />
                      </IconButton>
                    </>
                  ) : index === 0 &&
                    reservationState.visitorsList.length !== 1 ? (
                    <IconButton onClick={() => nextTab()}>
                      <NextTabIcon />
                    </IconButton>
                  ) : (
                    index === reservationState.visitorsList.length - 1 &&
                    reservationState.visitorsList.length !== 1 && (
                      <IconButton onClick={() => lastTab()}>
                        <PreviousTabIcon />
                      </IconButton>
                    )
                  )}
                </div>
              </TabPanel>
            ))}
        </div>
      </div>
    </>
  );
};

export default withTrans(VisitorsInfo);

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

VisitorsInfo.propTypes = {
  reservationState: PropTypes.shape({
    visitorsList: PropTypes.arrayOf(
      PropTypes.shape({
        visitorType: PropTypes.string,
        priceType: PropTypes.string,
        price: PropTypes.number
      })
    ),
    activeTab: PropTypes.number.isRequired,
    errors_validation: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

VisitorsInfosCollapse.propTypes = {
  index: PropTypes.number.isRequired,
  reservationState: PropTypes.object.isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  tab: PropTypes.object.isRequired
};

VisitorAdultInfoForm.propTypes = {
  indexVisitor: PropTypes.number.isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  reservationState: PropTypes.object.isRequired
};

VisitorChildInfoForm.propTypes = {
  indexVisitor: PropTypes.number.isRequired,
  visitorType: PropTypes.string.isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  reservationState: PropTypes.object.isRequired
};
