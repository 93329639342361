export const countryTranslations = {
  AFGHANISTAN: { en: 'AFGHANISTAN', es: 'AFGANISTÁN' },
  'AFRIQUE DU SUD': { en: 'SOUTH AFRICA', es: 'SUDÁFRICA' },
  ALAND: { en: 'ÅLAND ISLANDS', es: 'ISLAS ÅLAND' },
  ALBANIE: { en: 'ALBANIA', es: 'ALBANIA' },
  ALGÉRIE: { en: 'ALGERIA', es: 'ARGELIA' },
  ALLEMAGNE: { en: 'GERMANY', es: 'ALEMANIA' },
  ANDORRE: { en: 'ANDORRA', es: 'ANDORRA' },
  ANGOLA: { en: 'ANGOLA', es: 'ANGOLA' },
  ANGUILLA: { en: 'ANGUILLA', es: 'ANGUILA' },
  ANTARCTIQUE: { en: 'ANTARCTICA', es: 'ANTÁRTIDA' },
  'ANTIGUA-ET-BARBUDA': { en: 'ANTIGUA AND BARBUDA', es: 'ANTIGUA Y BARBUDA' },
  'ARABIE SAOUDITE': { en: 'SAUDI ARABIA', es: 'ARABIA SAUDITA' },
  ARGENTINE: { en: 'ARGENTINA', es: 'ARGENTINA' },
  ARMÉNIE: { en: 'ARMENIA', es: 'ARMENIA' },
  ARUBA: { en: 'ARUBA', es: 'ARUBA' },
  AUSTRALIE: { en: 'AUSTRALIA', es: 'AUSTRALIA' },
  AUTRICHE: { en: 'AUSTRIA', es: 'AUSTRIA' },
  AZERBAÏDJAN: { en: 'AZERBAIJAN', es: 'AZERBAIYÁN' },
  BAHAMAS: { en: 'BAHAMAS', es: 'BAHAMAS' },
  BAHREÏN: { en: 'BAHRAIN', es: 'BARÉIN' },
  BANGLADESH: { en: 'BANGLADESH', es: 'BANGLADÉS' },
  BARBADE: { en: 'BARBADOS', es: 'BARBADOS' },
  BÉLARUS: { en: 'BELARUS', es: 'BIELORRUSIA' },
  BELGIQUE: { en: 'BELGIUM', es: 'BÉLGICA' },
  BELIZE: { en: 'BELIZE', es: 'BELICE' },
  BÉNIN: { en: 'BENIN', es: 'BENÍN' },
  BERMUDES: { en: 'BERMUDA', es: 'BERMUDAS' },
  BHOUTAN: { en: 'BHUTAN', es: 'BUTÁN' },
  BOLIVIE: { en: 'BOLIVIA', es: 'BOLIVIA' },
  'BONAIRE, SAINT-EUSTACHE ET SABA': {
    en: 'BONAIRE, SINT EUSTATIUS AND SABA',
    es: 'BONAIRE, SAN EUSTAQUIO Y SABA'
  },
  'BOSNIE-HERZÉGOVINE': {
    en: 'BOSNIA AND HERZEGOVINA',
    es: 'BOSNIA Y HERZEGOVINA'
  },
  BOTSWANA: { en: 'BOTSWANA', es: 'BOTSUANA' },
  BOUVET: { en: 'BOUVET ISLAND', es: 'ISLA BOUVET' },
  BRÉSIL: { en: 'BRAZIL', es: 'BRASIL' },
  'BRUNÉI DARUSSALAM': { en: 'BRUNEI DARUSSALAM', es: 'BRUNÉI' },
  BULGARIE: { en: 'BULGARIA', es: 'BULGARIA' },
  'BURKINA FASO': { en: 'BURKINA FASO', es: 'BURKINA FASO' },
  BURUNDI: { en: 'BURUNDI', es: 'BURUNDI' },
  'CABO VERDE': { en: 'CABO VERDE', es: 'CABO VERDE' },
  CAÏMANS: { en: 'CAYMAN ISLANDS', es: 'ISLAS CAIMÁN' },
  CAMBODGE: { en: 'CAMBODIA', es: 'CAMBOYA' },
  CAMEROUN: { en: 'CAMEROON', es: 'CAMERÚN' },
  CANADA: { en: 'CANADA', es: 'CANADÁ' },
  CHILI: { en: 'CHILE', es: 'CHILE' },
  CHINE: { en: 'CHINA', es: 'CHINA' },
  CHRISTMAS: { en: 'CHRISTMAS ISLAND', es: 'ISLA CHRISTMAS' },
  CHYPRE: { en: 'CYPRUS', es: 'CHIPRE' },
  'COCOS / KEELING': {
    en: 'COCOS (KEELING) ISLANDS',
    es: 'ISLAS COCOS (KEELING)'
  },
  COLOMBIE: { en: 'COLOMBIA', es: 'COLOMBIA' },
  COMORES: { en: 'COMOROS', es: 'COMORAS' },
  CONGO: { en: 'CONGO', es: 'CONGO' },
  COOK: { en: 'COOK ISLANDS', es: 'ISLAS COOK' },
  'COPENHAGEN K': { en: 'COPENHAGEN K', es: 'COPENHAGUE K' },
  'CORÉE (LA RÉPUBLIQUE DE)': { en: 'SOUTH KOREA', es: 'COREA DEL SUR' },
  'CORÉE (LA RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE)': {
    en: 'NORTH KOREA',
    es: 'COREA DEL NORTE'
  },
  'COSTA RICA': { en: 'COSTA RICA', es: 'COSTA RICA' },
  "CÔTE D'IVOIRE": { en: 'IVORY COAST', es: 'COSTA DE MARFIL' },
  CROATIE: { en: 'CROATIA', es: 'CROACIA' },
  CUBA: { en: 'CUBA', es: 'CUBA' },
  CURAÇAO: { en: 'CURAÇAO', es: 'CURAZAO' },
  DANEMARK: { en: 'DENMARK', es: 'DINAMARCA' },
  DJIBOUTI: { en: 'DJIBOUTI', es: 'YIBUTI' },
  DOMINIQUE: { en: 'DOMINICA', es: 'DOMINICA' },
  ÉGYPTE: { en: 'EGYPT', es: 'EGIPTO' },
  'EL SALVADOR': { en: 'EL SALVADOR', es: 'EL SALVADOR' },
  'ÉMIRATS ARABES UNIS': {
    en: 'UNITED ARAB EMIRATES',
    es: 'EMIRATOS ÁRABES UNIDOS'
  },
  ÉQUATEUR: { en: 'ECUADOR', es: 'ECUADOR' },
  ÉRYTHRÉE: { en: 'ERITREA', es: 'ERITREA' },
  ESPAGNE: { en: 'SPAIN', es: 'ESPAÑA' },
  ESTONIE: { en: 'ESTONIA', es: 'ESTONIA' },
  ESWATINI: { en: 'ESWATINI', es: 'ESWATINI' },
  "ÉTATS-UNIS D'AMÉRIQUE": {
    en: 'UNITED STATES OF AMERICA',
    es: 'ESTADOS UNIDOS DE AMÉRICA'
  },
  ÉTHIOPIE: { en: 'ETHIOPIA', es: 'ETIOPÍA' },
  'FALKLAND/MALOUINES': { en: 'FALKLAND ISLANDS', es: 'ISLAS MALVINAS' },
  FÉROÉ: { en: 'FAROE ISLANDS', es: 'ISLAS FEROE' },
  FIDJI: { en: 'FIJI', es: 'FIYI' },
  FINLANDE: { en: 'FINLAND', es: 'FINLANDIA' },
  GABON: { en: 'GABON', es: 'GABÓN' },
  GAMBIE: { en: 'GAMBIA', es: 'GAMBIA' },
  GÉORGIE: { en: 'GEORGIA', es: 'GEORGIA' },
  'GÉORGIE DU SUD-ET-LES ÎLES SANDWICH DU SUD': {
    en: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    es: 'GEORGIA DEL SUR Y LAS ISLAS SANDWICH DEL SUR'
  },
  GHANA: { en: 'GHANA', es: 'GHANA' },
  GIBRALTAR: { en: 'GIBRALTAR', es: 'GIBRALTAR' },
  GRÈCE: { en: 'GREECE', es: 'GRECIA' },
  GRENADE: { en: 'GRENADA', es: 'GRANADA' },
  GRIEKENLAND: { en: 'GREECE', es: 'GRECIA' }, // Duplicate with GRÈCE
  GROENLAND: { en: 'GREENLAND', es: 'GROENLANDIA' },
  GUADELOUPE: { en: 'GUADELOUPE', es: 'GUADALUPE' },
  GUAM: { en: 'GUAM', es: 'GUAM' },
  GUATEMALA: { en: 'GUATEMALA', es: 'GUATEMALA' },
  GUERNESEY: { en: 'GUERNSEY', es: 'GUERNSEY' },
  GUINÉE: { en: 'GUINEA', es: 'GUINEA' },
  'GUINÉE-BISSAU': { en: 'GUINEA-BISSAU', es: 'GUINEA-BISSAU' },
  GUYANA: { en: 'GUYANA', es: 'GUYANA' },
  'GUYANE FRANÇAISE': { en: 'FRENCH GUIANA', es: 'GUAYANA FRANCESA' },
  HAÏTI: { en: 'HAITI', es: 'HAITÍ' },
  'HEARD-ET-ÎLES MACDONALD': {
    en: 'HEARD ISLAND AND MCDONALD ISLANDS',
    es: 'ISLAS HEARD Y MCDONALD'
  },
  HONDURAS: { en: 'HONDURAS', es: 'HONDURAS' },
  'HONG KONG': { en: 'HONG KONG', es: 'HONG KONG' },
  HONGRIE: { en: 'HUNGARY', es: 'HUNGRÍA' },
  'ÎLE DE MAN': { en: 'ISLE OF MAN', es: 'ISLA DE MAN' },
  'ÎLE NORFOLK': { en: 'NORFOLK ISLAND', es: 'ISLA NORFOLK' },
  'ÎLES MINEURES ÉLOIGNÉES DES ÉTATS-UNIS': {
    en: 'UNITED STATES MINOR OUTLYING ISLANDS',
    es: 'ISLAS MENORES ALEJADAS DE LOS ESTADOS UNIDOS'
  },
  'ÎLES VIERGES BRITANNIQUES': {
    en: 'BRITISH VIRGIN ISLANDS',
    es: 'ISLAS VÍRGENES BRITÁNICAS'
  },
  'ÎLES VIERGES DES ÉTATS-UNIS': {
    en: 'VIRGIN ISLANDS, U.S.',
    es: 'ISLAS VÍRGENES DE LOS ESTADOS UNIDOS'
  },
  INDE: { en: 'INDIA', es: 'INDIA' },
  INDONÉSIE: { en: 'INDONESIA', es: 'INDONESIA' },
  "IRAN (RÉPUBLIQUE ISLAMIQUE D')": { en: 'IRAN', es: 'IRÁN' },
  IRAQ: { en: 'IRAQ', es: 'IRAQ' },
  IRLANDE: { en: 'IRELAND', es: 'IRLANDA' },
  ISLANDE: { en: 'ICELAND', es: 'ISLANDIA' },
  ISRAËL: { en: 'ISRAEL', es: 'ISRAEL' },
  ITALIE: { en: 'ITALY', es: 'ITALIA' },
  JAMAÏQUE: { en: 'JAMAICA', es: 'JAMAICA' },
  JAPON: { en: 'JAPAN', es: 'JAPÓN' },
  JERSEY: { en: 'JERSEY', es: 'JERSEY' },
  JORDANIE: { en: 'JORDAN', es: 'JORDANIA' },
  KAZAKHSTAN: { en: 'KAZAKHSTAN', es: 'KAZAJISTÁN' },
  KENYA: { en: 'KENYA', es: 'KENIA' },
  KIRGHIZISTAN: { en: 'KYRGYZSTAN', es: 'KIRGUISTÁN' },
  KIRIBATI: { en: 'KIRIBATI', es: 'KIRIBATI' },
  KOWEÏT: { en: 'KUWAIT', es: 'KUWAIT' },
  'LA RÉUNION': { en: 'RÉUNION', es: 'REUNIÓN' },
  LAO: { en: 'LAOS', es: 'LAOS' },
  LESOTHO: { en: 'LESOTHO', es: 'LESOTO' },
  LETTONIE: { en: 'LATVIA', es: 'LETONIA' },
  LIBAN: { en: 'LEBANON', es: 'LÍBANO' },
  LIBÉRIA: { en: 'LIBERIA', es: 'LIBERIA' },
  LIBYE: { en: 'LIBYA', es: 'LIBIA' },
  LIECHTENSTEIN: { en: 'LIECHTENSTEIN', es: 'LIECHTENSTEIN' },
  LITUANIE: { en: 'LITHUANIA', es: 'LITUANIA' },
  LUXEMBOURG: { en: 'LUXEMBOURG', es: 'LUXEMBURGO' },
  MACAO: { en: 'MACAO', es: 'MACAO' },
  'MACÉDOINE DU NORD': { en: 'NORTH MACEDONIA', es: 'MACEDONIA DEL NORTE' },
  MADAGASCAR: { en: 'MADAGASCAR', es: 'MADAGASCAR' },
  MALAISIE: { en: 'MALAYSIA', es: 'MALASIA' },
  MALAWI: { en: 'MALAWI', es: 'MALAWI' },
  MALDIVES: { en: 'MALDIVES', es: 'MALDIVAS' },
  MALI: { en: 'MALI', es: 'MALI' },
  MALTE: { en: 'MALTA', es: 'MALTA' },
  'MARIANNES DU NORD': {
    en: 'NORTHERN MARIANA ISLANDS',
    es: 'ISLAS MARIANAS DEL NORTE'
  },
  MAROC: { en: 'MOROCCO', es: 'MARRUECOS' },
  MARSHALL: { en: 'MARSHALL ISLANDS', es: 'ISLAS MARSHALL' },
  MARTINIQUE: { en: 'MARTINIQUE', es: 'MARTINICA' },
  MAURICE: { en: 'MAURITIUS', es: 'MAURICIO' },
  MAURITANIE: { en: 'MAURITANIA', es: 'MAURITANIA' },
  MAYOTTE: { en: 'MAYOTTE', es: 'MAYOTTE' },
  MEXIQUE: { en: 'MEXICO', es: 'MÉXICO' },
  MICRONÉSIE: { en: 'MICRONESIA', es: 'MICRONESIA' },
  MOLDOVA: { en: 'MOLDOVA', es: 'MOLDAVIA' },
  MONACO: { en: 'MONACO', es: 'MÓNACO' },
  MONGOLIE: { en: 'MONGOLIA', es: 'MONGOLIA' },
  MONTÉNÉGRO: { en: 'MONTENEGRO', es: 'MONTENEGRO' },
  MONTSERRAT: { en: 'MONTSERRAT', es: 'MONTSERRAT' },
  MOZAMBIQUE: { en: 'MOZAMBIQUE', es: 'MOZAMBIQUE' },
  MYANMAR: { en: 'MYANMAR', es: 'MYANMAR' },
  NAMIBIE: { en: 'NAMIBIA', es: 'NAMIBIA' },
  NAURU: { en: 'NAURU', es: 'NAURU' },
  NÉPAL: { en: 'NEPAL', es: 'NEPAL' },
  NICARAGUA: { en: 'NICARAGUA', es: 'NICARAGUA' },
  NIGER: { en: 'NIGER', es: 'NÍGER' },
  NIGÉRIA: { en: 'NIGERIA', es: 'NIGERIA' },
  NIUE: { en: 'NIUE', es: 'NIUE' },
  NORVÈGE: { en: 'NORWAY', es: 'NORUEGA' },
  'NOUVELLE-CALÉDONIE': { en: 'NEW CALEDONIA', es: 'NUEVA CALEDONIA' },
  'NOUVELLE-ZÉLANDE': { en: 'NEW ZEALAND', es: 'NUEVA ZELANDA' },
  OMAN: { en: 'OMAN', es: 'OMÁN' },
  OUGANDA: { en: 'UGANDA', es: 'UGANDA' },
  OUZBÉKISTAN: { en: 'UZBEKISTAN', es: 'UZBEKISTÁN' },
  PAKISTAN: { en: 'PAKISTAN', es: 'PAKISTÁN' },
  PALAOS: { en: 'PALAU', es: 'PALAOS' },
  PALESTINE: { en: 'PALESTINE', es: 'PALESTINA' },
  PANAMA: { en: 'PANAMA', es: 'PANAMÁ' },
  'PAPOUASIE-NOUVELLE-GUINÉE': {
    en: 'PAPUA NEW GUINEA',
    es: 'PAPÚA NUEVA GUINEA'
  },
  PARAGUAY: { en: 'PARAGUAY', es: 'PARAGUAY' },
  'PAYS-BAS': { en: 'NETHERLANDS', es: 'PAÍSES BAJOS' },
  PÉROU: { en: 'PERU', es: 'PERÚ' },
  PHILIPPINES: { en: 'PHILIPPINES', es: 'FILIPINAS' },
  PITCAIRN: { en: 'PITCAIRN ISLANDS', es: 'ISLAS PITCAIRN' },
  POLOGNE: { en: 'POLAND', es: 'POLONIA' },
  'POLYNÉSIE FRANÇAISE': { en: 'FRENCH POLYNESIA', es: 'POLINESIA FRANCESA' },
  'PORTO RICO': { en: 'PUERTO RICO', es: 'PUERTO RICO' },
  PORTUGAL: { en: 'PORTUGAL', es: 'PORTUGAL' },
  QATAR: { en: 'QATAR', es: 'CATAR' },
  'RÉPUBLIQUE CENTRAFRICAINE': {
    en: 'CENTRAL AFRICAN REPUBLIC',
    es: 'REPÚBLICA CENTROAFRICANA'
  },
  'RÉPUBLIQUE DÉMOCRATIQUE DU CONGO': {
    en: 'DEMOCRATIC REPUBLIC OF THE CONGO',
    es: 'REPÚBLICA DEMOCRÁTICA DEL CONGO'
  },
  'RÉPUBLIQUE DOMINICAINE': {
    en: 'DOMINICAN REPUBLIC',
    es: 'REPÚBLICA DOMINICANA'
  },
  'RÉPUBLIQUE TCHÈQUE': { en: 'CZECH REPUBLIC', es: 'REPÚBLICA CHECA' },
  ROUMANIE: { en: 'ROMANIA', es: 'RUMANÍA' },
  'ROYAUME-UNI': { en: 'UNITED KINGDOM', es: 'REINO UNIDO' },
  RUSSIE: { en: 'RUSSIA', es: 'RUSIA' },
  RWANDA: { en: 'RWANDA', es: 'RUANDA' },
  'SAHARA OCCIDENTAL': { en: 'WESTERN SAHARA', es: 'SAHARA OCCIDENTAL' },
  'SAINT-BARTHÉLEMY': { en: 'SAINT BARTHELEMY', es: 'SAN BARTOLOMÉ' },
  'SAINT-MARIN': { en: 'SAN MARINO', es: 'SAN MARINO' },
  'SAINT-MARTIN (PARTIE FRANÇAISE)': {
    en: 'SAINT MARTIN (FRENCH PART)',
    es: 'SAN MARTÍN (PARTE FRANCESA)'
  },
  'SAINT-MARTIN (PARTIE NÉERLANDAISE)': {
    en: 'SAINT MARTIN (DUTCH PART)',
    es: 'SAN MARTÍN (PARTE HOLANDESA)'
  },
  'SAINT-PIERRE-ET-MIQUELON': {
    en: 'SAINT PIERRE AND MIQUELON',
    es: 'SAN PEDRO Y MIQUELÓN'
  },
  'SAINT-SIÈGE': { en: 'HOLY SEE', es: 'SANTA SEDE' },
  'SAINT-VINCENT-ET-LES GRENADINES': {
    en: 'SAINT VINCENT AND THE GRENADINES',
    es: 'SAN VICENTE Y LAS GRANADINAS'
  },
  'SAINTE-HÉLÈNE, ASCENSION ET TRISTAN DA CUNHA': {
    en: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    es: 'SANTA ELENA, ASCENSIÓN Y TRISTÁN DE ACUÑA'
  },
  'SAINTE-LUCIE': { en: 'SAINT LUCIA', es: 'SANTA LUCÍA' },
  SALOMON: { en: 'SOLOMON ISLANDS', es: 'ISLAS SALOMÓN' },
  SAMOA: { en: 'SAMOA', es: 'SAMOA' },
  'SAMOA AMÉRICAINES': { en: 'AMERICAN SAMOA', es: 'SAMOA AMERICANA' },
  'SAO TOMÉ-ET-PRINCIPE': {
    en: 'SAO TOME AND PRINCIPE',
    es: 'SANTO TOMÉ Y PRÍNCIPE'
  },
  SÉNÉGAL: { en: 'SENEGAL', es: 'SENEGAL' },
  SERBIE: { en: 'SERBIA', es: 'SERBIA' },
  SEYCHELLES: { en: 'SEYCHELLES', es: 'SEYCHELLES' },
  'SIERRA LEONE': { en: 'SIERRA LEONE', es: 'SIERRA LEONA' },
  SINGAPOUR: { en: 'SINGAPORE', es: 'SINGAPUR' },
  SLOVAQUIE: { en: 'SLOVAKIA', es: 'ESLOVAQUIA' },
  SLOVÉNIE: { en: 'SLOVENIA', es: 'ESLOVENIA' },
  SOMALIE: { en: 'SOMALIA', es: 'SOMALIA' },
  SOUDAN: { en: 'SUDAN', es: 'SUDÁN' },
  'SOUDAN DU SUD': { en: 'SOUTH SUDAN', es: 'SUDÁN DEL SUR' },
  SPANJE: { en: 'SPAIN', es: 'ESPAÑA' }, // Duplicate with ESPAGNE
  'SRI LANKA': { en: 'SRI LANKA', es: 'SRI LANKA' },
  SUÈDE: { en: 'SWEDEN', es: 'SUECIA' },
  SUISSE: { en: 'SWITZERLAND', es: 'SUIZA' },
  SURINAME: { en: 'SURINAME', es: 'SURINAM' },
  'SVALBARD LE JAN MAYEN': {
    en: 'SVALBARD AND JAN MAYEN',
    es: 'SVALBARD Y JAN MAYEN'
  },
  SYRIE: { en: 'SYRIA', es: 'SIRIA' },
  TADJIKISTAN: { en: 'TAJIKISTAN', es: 'TAYIKISTÁN' },
  'TAÏWAN (PROVINCE DE CHINE)': { en: 'TAIWAN', es: 'TAIWÁN' },
  TANZANIE: { en: 'TANZANIA', es: 'TANZANIA' },
  TCHAD: { en: 'CHAD', es: 'CHAD' },
  'TERRES AUSTRALES FRANÇAISES': {
    en: 'FRENCH SOUTHERN TERRITORIES',
    es: 'TERRITORIOS AUSTRALES FRANCESES'
  },
  "TERRITOIRE BRITANNIQUE DE L'OCÉAN INDIEN": {
    en: 'BRITISH INDIAN OCEAN TERRITORY',
    es: 'TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO'
  },
  THAÏLANDE: { en: 'THAILAND', es: 'TAILANDIA' },
  'TIMOR-LESTE': { en: 'TIMOR-LESTE', es: 'TIMOR-LESTE' },
  TOGO: { en: 'TOGO', es: 'TOGO' },
  TOKELAU: { en: 'TOKELAU', es: 'TOKELAU' },
  TONGA: { en: 'TONGA', es: 'TONGA' },
  'TRINITÉ-ET-TOBAGO': { en: 'TRINIDAD AND TOBAGO', es: 'TRINIDAD Y TOBAGO' },
  TUNISIE: { en: 'TUNISIA', es: 'TÚNEZ' },
  TURKMÉNISTAN: { en: 'TURKMENISTAN', es: 'TURKMENISTÁN' },
  'TURKS-ET-CAÏCOS': {
    en: 'TURKS AND CAICOS ISLANDS',
    es: 'ISLAS TURCAS Y CAICOS'
  },
  TURQUIE: { en: 'TURKEY', es: 'TURQUÍA' },
  TUVALU: { en: 'TUVALU', es: 'TUVALU' },
  UKRAINE: { en: 'UKRAINE', es: 'UCRANIA' },
  URUGUAY: { en: 'URUGUAY', es: 'URUGUAY' },
  VANUATU: { en: 'VANUATU', es: 'VANUATU' },
  VENEZUELA: { en: 'VENEZUELA', es: 'VENEZUELA' },
  'VIET NAM': { en: 'VIETNAM', es: 'VIETNAM' },
  'WALLIS-ET-FUTUNA': { en: 'WALLIS AND FUTUNA', es: 'WALLIS Y FUTUNA' },
  YÉMEN: { en: 'YEMEN', es: 'YEMEN' },
  ZAMBIE: { en: 'ZAMBIA', es: 'ZAMBIA' },
  ZIMBABWE: { en: 'ZIMBABWE', es: 'ZIMBABUE' }
};
